import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ChartsService {

  refreshFiltersSubject: Subject<boolean> = new Subject<boolean>();
  removeFilterSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  openFiltersSubject: Subject<boolean> = new Subject<boolean>();
  chipsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  selectedTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  toggleMoreFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  productionDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  goHome: BehaviorSubject<any> = new BehaviorSubject<any>('home');

  getChips(activeFilters: any,): any {
    let chips = [];
    Object.keys(activeFilters).forEach(key => {
      if (activeFilters[key]?.length) {
        if (Array.isArray(activeFilters[key])) {
          activeFilters[key].forEach(filter => {
            chips.push({ name: filter, value: filter, type: key });
          });
        }
        else {
          if (key == 'dateType') {
            if (activeFilters['dateType'].toLowerCase() == 'custom') {
              chips.push({ name: `Custom (${moment(activeFilters.startDate).format('YYYY-MM-DD')} to ${moment(activeFilters.endDate).format('YYYY-MM-DD')})`, value: activeFilters[key], type: key });
            }
            else {
              chips.push({ name: activeFilters[key], value: activeFilters[key], type: key });
            }
          }
          else {
            if (key != 'agency')
              chips.push({ name: activeFilters[key], value: activeFilters[key], type: key });
          }
        }
      }
    });
    this.chipsSubject.next(chips);
    return chips;
  }
}